import React from "react"
import { Filter } from "../styles/FilterItem.styled"
import box from "../assets/box.svg"
import check from "../assets/checkedbox.svg"

const FilterItem = ({
  cat,
  activeFilters,
  setActiveFilters,
  handleFilters,
}) => {
  const itemActive = activeFilters?.includes(cat)
  const handleClick = (filter, activeFilters, setActiveFilters) => {
    if (activeFilters.includes(filter)) {
      setActiveFilters(
        activeFilters.filter(activeFilter => activeFilter !== filter)
      )
    } else {
      setActiveFilters([...activeFilters, filter])
    }
  }

  return (
    <Filter
      className={itemActive ? "filter-active" : "filter"}
      onClick={() => handleClick(cat, activeFilters, setActiveFilters)}
    >
      <img src={itemActive ? check : box} alt="icon" />
      {cat}
    </Filter>
  )
}

export default FilterItem
