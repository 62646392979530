import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  width: max-content;
  margin: 0 auto;
  gap: 40px;
  @media (max-width: 576px) {
    gap: 15px;
  }
`

export const Button = styled.button`
  background: #002dc2;
  border: none;
  color: #ffffff;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 576px) {
    height: 36px;
    width: 36px;
  }
`

export const PageNumbers = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  @media (max-width: 576px) {
    gap: 12px;
  }
`
export const Number = styled.button`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #c1c1c1;
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
    color: #1b1b1b;
  }
  &.active {
    color: #1b1b1b;
  }
`
