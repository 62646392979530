import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 371px;
  height: max-content;
  background: #f9fafb;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  padding: 32px;
  margin-left: auto;
  @media (max-width: 992px) {
    max-width: 100%;
    align-items: center;
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    margin-bottom: 72px;
  }
  .heading {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #4e4e4e;
    border-bottom: 1px solid #f0f0f0;
    width: max-content;
    padding-bottom: 12px;
  }
  div {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    @media (max-width: 992px) {
      flex-direction: row;
      column-gap: 16px;
      flex-wrap: wrap;
      justify-content: center;
      width: 75%;
    }
    @media (max-width: 770px) {
      width: 95%;
    }
    button {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: #0e0e0e;
      width: max-content;
      background: transparent;
      border: none;
      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }
  }
`
