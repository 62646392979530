import React, { useState } from "react"
import {
  Wrapper,
  Button,
  PageNumbers,
  Number,
} from "../styles/Pagination.styled"

const Pagination = ({ currentPage, pageNumbers, paginate }) => {
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(5)
  const [showFirstLast, setShowFirstLast] = useState(false)
  const lastPage = pageNumbers.length

  const handleFirst = () => {
    setStartIndex(0)
    setEndIndex(5)
    paginate(1)
    setShowFirstLast(false)
  }

  const handleLast = () => {
    setStartIndex(lastPage - 4)
    setEndIndex(lastPage - 1)
    paginate(lastPage)
  }

  const handlePrevious = () => {
    if (currentPage > 1 && currentPage <= 5) {
      setStartIndex(0)
      setEndIndex(5)
      setShowFirstLast(false)
      paginate(currentPage - 1)
    } else if (currentPage >= 6 && currentPage !== lastPage) {
      setStartIndex(currentPage - 3)
      setEndIndex(currentPage)
      setShowFirstLast(true)
      paginate(currentPage - 1)
    } else if (currentPage === lastPage) {
      setStartIndex(lastPage - 4)
      setEndIndex(lastPage - 1)
      paginate(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < 5) {
      setStartIndex(0)
      setEndIndex(5)
      setShowFirstLast(false)
      paginate(currentPage + 1)
    } else if (currentPage + 1 < lastPage && currentPage + 1 !== lastPage) {
      setStartIndex(currentPage - 1)
      setEndIndex(currentPage + 2)
      setShowFirstLast(true)
      paginate(currentPage + 1)
    } else if (currentPage + 1 === lastPage) {
      handleLast()
    }
  }

  return (
    <Wrapper>
      <Button type="button" onClick={() => handlePrevious()}>
        <img
          className="previous"
          src={
            currentPage === 1
              ? "/icons/pageButtonLeftDisabled.svg"
              : "/icons/pageButtonLeft.svg"
          }
          alt="previous"
        />
      </Button>
      <PageNumbers>
        {showFirstLast && <Number onClick={() => handleFirst()}>1</Number>}
        {pageNumbers.slice(startIndex, endIndex).map(index => {
          return (
            <Number
              onClick={() => paginate(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </Number>
          )
        })}
        {showFirstLast && (
          <Number
            onClick={() => handleLast()}
            className={currentPage === lastPage ? "active" : ""}
          >
            {lastPage}
          </Number>
        )}
      </PageNumbers>
      <Button type="button" onClick={() => handleNext()}>
        <img
          className="next"
          src={
            currentPage === lastPage
              ? "/icons/pageButtonRightDisabled.svg"
              : "/icons/pageButtonRight.svg"
          }
          alt="next"
        />
      </Button>
    </Wrapper>
  )
}

export default Pagination
