import React from 'react'
import { Text } from '@atoms'
import {
  TrustBarContainer,
  LogosContainer
} from "./styles/ConversionPanelReference.styled"
import OptimizedImage from '../../utils/optimizedImage'

const ConversionPanelTrustbar = ({ trustBar, variant, background }) => {
  let heading = trustBar?.heading
  let companies = trustBar?.companies

  return (
    <TrustBarContainer>
      <Text textVariant={variant(background)} className='trust-bar-heading'>
        {heading}
      </Text>
      <LogosContainer>
        {companies?.map((company, idx) => {
          return (
            <OptimizedImage image={company?.logo?.gatsbyImageData} key={idx} src={company?.logo?.file?.url} alt={`${company?.name} logo`}/>
          )
        })}
      </LogosContainer>
    </TrustBarContainer>
  )
}

export default ConversionPanelTrustbar 