import React, { useState } from "react"
import FilterItem from "./FilterItem"
import { Container, Button, Filters, Icon } from "../styles/FilterGroup.styled"
import Arrow from "../assets/down-arrow.svg"

const FilterGroup = ({
  options,
  activeFilters,
  setActiveFilters,
  handleFilters,
}) => {
  const [expanded, setExpanded] = useState(false)
  return (
    <Container className="filter-group" expanded={expanded}>
      <Button expanded={expanded} onClick={() => setExpanded(!expanded)}>
        All
        <Icon src={Arrow} alt="" expanded={expanded} />
      </Button>
      {options && options?.length > 0 && (
        <Filters expanded={expanded}>
          {options.map(cat => (
            <FilterItem
              key={cat}
              cat={cat}
              activeFilters={activeFilters}
              setActiveFilters={setActiveFilters}
              handleFilters={handleFilters}
            />
          ))}
        </Filters>
      )}
    </Container>
  )
}

export default FilterGroup
