import styled from "styled-components"

export const Filter = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 24px;
  margin: 0px;
  background: #e4e9f1;
  border: none;
  background: transparent;
  outline: none;
  color: #1b1b1b;
  text-align: left;
  font-size: 16px;
  &:hover {
    background-color: #9caeff;
    color: #1b1b1b;
    outline: none;
  }
`
