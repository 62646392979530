import { useState, useEffect } from "react"
import useFirstRender from "../../../hooks/useFirstRender"

export const useFilters = formattedData => {
  const firstRender = useFirstRender()
  const [searchValue, setSearchValue] = useState("")
  const [categoryFilters, setCategoryFilters] = useState([])
  const [typeFilters, setTypeFilters] = useState([])
  const [otherFilters, setOtherFilters] = useState([])
  const [filteredBySearch, setFilteredBySearch] = useState([])
  const [sortedByDate, setSortedByDate] = useState(false)

  // Source of truth for posts
  const [activeFilteredPosts, setActiveFilteredPosts] = useState(formattedData)

  // ----- Search Filter Funtion ----- //
  const searchResults = searchValue => {
    const searchResults = formattedData?.filter(node => {
      return node?.title?.toLowerCase()?.includes(searchValue.toLowerCase())
    })
    setFilteredBySearch([...searchResults])
  }

  // ------- Set Search Value ------- //
  useEffect(() => {
    searchResults(searchValue)
  }, [searchValue])

  // ------- Set Active Posts based on searchValue ------- //
  useEffect(() => {
    setActiveFilteredPosts([...filteredBySearch])
  }, [filteredBySearch])

  // ------ Filter Functions ------ //
  const handleFilterByCategory = () => {
    if (categoryFilters?.length === 0) {
      setActiveFilteredPosts(formattedData)
      return
    }
    if (activeFilteredPosts.length > 0) {
      setActiveFilteredPosts([
        ...activeFilteredPosts.filter(post => {
          return post?.categories?.some(cat =>
            categoryFilters.includes(cat?.title)
          )
        }),
      ])
    } else {
      setActiveFilteredPosts([
        ...filteredBySearch.filter(post =>
          post?.categories?.some(cat => categoryFilters.includes(cat?.title))
        ),
      ])
    }
  }

  const handleFilterByType = () => {
    if (typeFilters?.length === 0) {
      setActiveFilteredPosts(formattedData)
      return
    }
    if (activeFilteredPosts.length > 0) {
      setActiveFilteredPosts([
        ...activeFilteredPosts.filter(post => typeFilters?.includes(post.type)),
      ])
    } else {
      setActiveFilteredPosts([
        ...activeFilteredPosts.filter(post => typeFilters?.includes(post.type)),
      ])
    }
  }

  const handleFilterByOther = () => {
    console.log(activeFilteredPosts)
    if (otherFilters?.length === 0) {
      setActiveFilteredPosts(formattedData)
      return
    }
    if (activeFilteredPosts.length > 0) {
      setActiveFilteredPosts([
        ...activeFilteredPosts.filter(post =>
          otherFilters?.includes(post.other)
        ),
      ])
    } else {
      setActiveFilteredPosts([
        ...activeFilteredPosts.filter(post =>
          otherFilters?.includes(post.other)
        ),
      ])
    }
  }

  const sortByDate = () => {
    if (sortedByDate) {
      if (typeFilters?.length === 0) {
        setActiveFilteredPosts(
          formattedData.sort((a, b) => b.sortDate - a.sortDate)
        )
        return
      }
      if (activeFilteredPosts.length > 0) {
        setActiveFilteredPosts([
          activeFilteredPosts.sort((a, b) => b.sortDate - a.sortDate),
        ])
      }
    } else {
      if (typeFilters?.length === 0) {
        setActiveFilteredPosts(formattedData)
        return
      }
      if (activeFilteredPosts.length > 0) {
        setActiveFilteredPosts(
          activeFilteredPosts.sort((a, b) => a.title.localeCompare(b.title))
        )
      }
    }
  }

  // ---- Run Filters ----- //
  useEffect(() => {
    if (firstRender) return
    handleFilterByCategory()
  }, [categoryFilters])

  useEffect(() => {
    if (firstRender) return
    handleFilterByType()
    //
  }, [typeFilters])

  useEffect(() => {
    if (firstRender) return
    handleFilterByOther()
  }, [otherFilters])

  useEffect(() => {
    if (firstRender) return
    sortByDate()
  }, [sortedByDate])

  // ------ Reset Filters ----- //
  const resetFilters = () => {
    setCategoryFilters([])
    setTypeFilters([])
    setOtherFilters([])
  }

  return {
    searchValue,
    setSearchValue,
    categoryFilters,
    setCategoryFilters,
    typeFilters,
    setTypeFilters,
    handleFilterByType,
    otherFilters,
    setOtherFilters,
    handleFilterByOther,
    handleFilterByCategory,
    activeFilteredPosts,
    resetFilters,
    setSortedByDate,
    sortedByDate,
  }
}
