import styled, { css } from "styled-components";

export const ConversionPanelContainer = styled.div`
  margin: 0 auto;
  align-items: center;
  backdrop-filter: blur(25px);
  padding-top: 96px;
  padding-bottom: 96px;
  justify-content: center;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 48px!important;
    padding-bottom: 48px!important;
  }
  .background {
    position: absolute;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    img {
      object-position: top right;
    }
  }
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
    @media (max-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (max-width: 768px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
`

export const Content = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  ${({ reference }) => reference && `
    justify-content: space-between!important;
  `}
  ${({ alignItems }) => alignItems === 'left' ? `
    justify-content: left;` : `justify-content: center;
  `}
  width: 100%;
  max-width: 1170px;
  margin-left: 15px;
  margin-right: 15px;
  @media (max-width: 992px) {
    max-width: 770px;
    flex-direction: column;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
`

export const ContentFlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${({ ctaCount, textAlign, reference }) =>
    (reference) ? `
      flex-direction: column;
      align-items: left;` 
    : 
    (ctaCount === 1 && textAlign === "left" && !reference) ? `
      flex-direction: row;
      justify-content: space-between;
      align-items: center;` 
    : ` 
      flex-direction: column;
      align-items: center;
    `
  }
  ${({ textAlign }) => 
    textAlign === "left" ? `
      text-align: left;
      @media (max-width: 992px) {
        text-align: center;
      }` 
    : `
      text-align: center;
    `
  }
  @media (max-width: 992px) {
    flex-direction: column;
    max-width: 770px;
    align-items: center;
  }
  @media (max-width: 768px) {
    max-width: 506px;
  }
  h1 {
    @media (max-width: 992px) {
      font-size: 40px;
      line-height: 48px;
    }
  }
`

export const CTAContainer = styled.div`
  display: flex;
  ${({ ctaCount }) => ctaCount > 1 &&`
    .button-wrapper {
      margin-right: 10px;
    }`
  }
  @media (max-width: 992px) {
    margin: 0 5px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    .button-wrapper {
      width: 100%;
      margin-left: 0px !important;
      margin-right: 0px !important;
      margin-bottom: 16px !important;
      button {
        width: 100%;
      }
    }
  }
`