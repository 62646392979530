import styled from "styled-components"
import searchHeroImg from "../../../assets/images/search-hero-bg.svg"

export const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 370px;
  background-image: url(${searchHeroImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-top: 100px;
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: 631px;
  padding: 0px 30px;
`
export const Form = styled.form`
  display: flex;
  width: 100%;
`

export const Input = styled.input`
  height: 40px;
  width: 100%;
  padding: 10px 0px 10px 20px;
  border-radius: 40px 0px 0px 40px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b1b;
  &:focus-visible {
    outline: none;
  }
`
export const InputWrapper = styled.div`
  flex-basis: 80%;
  position: relative;
  img {
    position: absolute;
    top: 15px;
    right: 20px;
    &:hover {
      cursor: pointer;
    }
  }
`

export const SubmitBtn = styled.input`
  height: 40px;
  flex-basis: 20%;
  background: #050036;
  color: #fff;
  border-radius: 0px 40px 40px 0px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  &:hover {
    background: #090069;
    color: #fff;
    border: 1px solid #090069;
    cursor: pointer;
  }
  @media (max-width: 576px) {
    font-size: 12px;
  }
`
