import styled from "styled-components"

export const Results = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 770px;
  padding: 80px 40px 40px 40px;
  gap: 40px;
  margin: 0 auto;
  position: relative;
`

export const ResultsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ResultsHeading = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #1b1b1b;
  position: relative;
  @media (max-width: 567px) {
    font-size: 18px;
  }
`

export const HR = styled.div`
  height: 4px;
  width: 40px;
  background: #002dc2;
  border-radius: 2px;
  position: absolute;
  left: 0;
  top: -20px;
`

export const ResultsTotal = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #1b1b1b;
`

export const ResultsItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  gap: 14px;
  border-bottom: 1px solid #cccccc;
`

export const Title = styled.a`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #002dc2;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`

export const Description = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #171717;
`

export const Metadata = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
`
export const MetaText = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #1b1b1b;
  .icon {
    margin-right: 8px;
  }
`
export const BackToTop = styled.a`
  position: absolute;
  right: 0px;
  bottom: 40px;
  z-index: 1000;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  padding: 14px 15px;
  background: #fff;
  color: #002dc2;
  border: 1px solid #002dc2;
  border-radius: 40px;
  transition: all 0.2s ease-in 0s;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    background-color: #5260ff;
    color: #fff;
    border: 1px solid #5260ff;
  }
  @media (max-width: 576px) {
    bottom: 110px;
  }
`
