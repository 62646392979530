export const formatData = data => {
  const pages = data.allContentfulPage.edges
  const blogs = data.allContentfulBlogPost.edges
  const resources = data.allContentfulResource.edges
  const knowledgeCenterPosts = data.allContentfulKnowledgeCenterPost.edges
  const newsroomPosts = data.allContentfulNewsroomPost.edges
  const formattedData = []

  const resourceTypes = {
    Infographic: "Infographic",
    Guide: "Guide",
    Video: "Video",
    Webinar: "Webinar",
    "Case Study": "Case Study",
  }

  const resourceSlugs = {
    Infographic: "/resource/infographic/",
    Guide: "/resource/guide/",
    Video: "/resource/video/",
    Webinar: "/resource/webinar/",
  }

  pages.map(page => {
    if (!page?.node?.pageSeo?.noIndex) {
      formattedData.push({
        slug: `/${page?.node?.slug}`,
        title: page?.node?.internalName,
        description: page?.node?.pageSeo?.seoDescription,
        sortDate: Date.parse(page?.node?.createdAt),
        categories:
          page?.node?.pageCategory !== null ? [...page.node.pageCategory] : [],
      })
    }
  })

  blogs.map(blog => {
    if (!blog?.node?.pageSeo?.noIndex) {
      formattedData.push({
        slug: `/blog/${blog?.node?.slug}`,
        title: blog?.node?.title,
        description: blog?.node?.description?.description,
        type: "Blog",
        formattedDate: blog?.node?.publishDate,
        sortDate: Date.parse(blog?.node?.publishDate),
        categories: [...blog?.node?.category],
      })
    }
  })

  resources.map(resource => {
    if (!resource?.node?.pageSeo?.noIndex) {
      formattedData.push({
        slug: `${resourceSlugs[resource?.node?.category[0]?.title]}${
          resource?.node?.slug
        }`,
        title: resource?.node?.title,
        description:
          resource?.node?.childContentfulResourceDescriptionTextNode
            ?.description,
        type: resourceTypes[resource?.node?.category[0]?.title],
        formattedDate: resource?.node?.publishDate,
        sortDate: Date.parse(resource?.node?.publishDate),
        externalUrl: resource?.node?.externalUrl,
      })
    }
  })

  knowledgeCenterPosts.map(post => {
    if (!post?.node?.pageSeo?.noIndex) {
      formattedData.push({
        slug: `/article/${post?.node?.slug}`,
        title: post?.node?.title,
        description:
          post?.node?.childContentfulKnowledgeCenterPostDescriptionTextNode
            ?.description,
        type: "Article",
        sortDate: Date.parse(post?.node?.createdAt),
      })
    }
  })

  newsroomPosts.map(post => {
    if (!post?.node?.pageSeo?.noIndex) {
      formattedData.push({
        slug: `/newsroom/${post?.node?.slug}`,
        title: post?.node?.title,
        description:
          post?.node?.childContentfulNewsroomPostDescriptionTextNode
            ?.description,
        formattedDate: post?.node?.publishDate,
        sortDate: Date.parse(post?.node?.publishDate),
        other: "Company News",
      })
    }
  })

  return formattedData.sort((a, b) => a.title.localeCompare(b.title))
}

export const contentTypeOptions = data => {
  const contentTypes = []
  const categories = []
  const other = []
  data.map(node => {
    if (node?.type) {
      contentTypes.push(node?.type)
    }
    if (node?.categories) {
      node?.categories.map(cat => {
        categories.push(cat?.title)
      })
    }
    if (node?.other) {
      other.push(node?.other)
    }
  })
  return {
    contentTypes: [...new Set(contentTypes)].sort(),
    categories: [...new Set(categories)].sort(),
    other: [...new Set(other)].sort(),
  }
}
