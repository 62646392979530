import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 371px;
  height: max-content;
  background: #f9fafb;
  border: 1px solid #f0f0f0;
  border-radius: 9px;
  padding: 32px;
  @media (max-width: 992px) {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    margin-bottom: 72px;
  }
`

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
`

export const Heading = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #4e4e4e;
`
export const HeadingOption = styled.button`
  border: none;
  background: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #002dc2;
  &:hover {
    cursor: pointer;
  }
`

export const FilterGroupHeading = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #4d4d4d;
`
export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    flex-direction: row;
    gap: 30px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: unset;
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media (max-width: 992px) {
    flex-basis: 33%;
  }
  @media (max-width: 768px) {
    flex-basis: unset;
  }
`
