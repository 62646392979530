import styled from "styled-components"

export const Section = styled.section`
  padding: 96px 30px;
`
export const Wrapper = styled.div`
  display: flex;
  max-width: 1170px;
  margin: 0 auto;
  @media (max-width: 992px) {
    flex-direction: column-reverse;
  }
`

export const SearchHeading = styled.h1`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  color: #1b1b1b;
  padding: 0px 40px 90px 40px;
  @media (max-width: 992px) {
    padding: 32px 40px 32px 40px;
  }
  @media (max-width: 567px) {
    font-size: 28px;
    padding: 0px 40px 32px 40px;
  }
`

export const NoResults = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b1b;
  padding-left: 40px;
  ul {
    margin-left: 30px;
  }
`
export const NoResultsHeading = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
`
