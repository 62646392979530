import React from "react"
import { Wrapper } from "../styles/NoResultsSidebar.styled"

const NoResultsSidebar = ({ setSearchValue }) => (
  <Wrapper>
    <p className="heading">SUGGESTED SEARCH</p>
    <div>
      <button
        onClick={() => {
          setSearchValue("Demo")
        }}
      >
        Demo
      </button>

      <button onClick={() => setSearchValue("Digital Friction")}>
        Digital Friction
      </button>

      <button onClick={() => setSearchValue("Digital Workplace")}>
        Digital Workplace
      </button>

      <button onClick={() => setSearchValue("Guided Attenion")}>
        Guided Attention
      </button>

      <button onClick={() => setSearchValue("Integrations")}>
        Integrations
      </button>

      <button onClick={() => setSearchValue("No-Code")}>No-Code</button>

      <button onClick={() => setSearchValue("Partners")}>Partners</button>
    </div>
  </Wrapper>
)

export default NoResultsSidebar
