import { useState, useEffect } from "react"

export const usePagination = (postsPerPage, posts = [], resetPage) => {
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = posts.length
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const activePosts = posts.slice(indexOfFirstPost, indexOfLastPost)
  const pageNumbersIndexes = Math.ceil(posts.length / postsPerPage)
  const pageNumbers = Array.from(Array(pageNumbersIndexes).keys())

  useEffect(() => {
    if (resetPage !== `undefined` && currentPage > 1 && resetPage === true) {
      setCurrentPage(1)
    }
  }, [currentPage, resetPage])

  const paginate = pageNumber => {
    setCurrentPage(pageNumber)
  }

  return {
    activePosts,
    pageNumbers,
    paginate,
    currentPage,
    nextPage,
    prevPage,
    totalPages,
  }
}
