import React, { useEffect, useState } from "react"
import {
  Section,
  Wrapper,
  Form,
  Input,
  SubmitBtn,
  InputWrapper,
} from "./styles/HeroSearch.styled"
import Close from "../../assets/icons/close-gray.svg"

const HeroSearch = ({ searchValue, setSearchValue }) => {
  const [value, setValue] = useState(searchValue)

  const handleSubmit = e => {
    e.preventDefault()
    setSearchValue(value)
  }

  const handleChange = e => {
    setValue(e.target.value)
  }

  const clearSearch = () => {
    setSearchValue("")
    setValue("")
  }

  useEffect(() => {
    setValue(searchValue)
  }, [searchValue])

  return (
    <Section>
      <Wrapper>
        <Form onSubmit={e => handleSubmit(e)}>
          <InputWrapper>
            <Input type="text" value={value} onChange={e => handleChange(e)} />
            {value && (
              <img src={Close} onClick={() => clearSearch()} alt="icon" />
            )}
          </InputWrapper>
          <SubmitBtn type="submit" value="Submit" />
        </Form>
      </Wrapper>
    </Section>
  )
}

export default HeroSearch
