import React from 'react';
import ConversionPanelTrustbar from './ConversionPanelTrustbar';
import ConversionPanelTestimonial from "./ConversionPanelTestimonial"

const ConversionPanelReference = ({ reference, variant, background }) => {
  if (reference?.__typename === "ContentfulComponentTrustbar") {
    return (
      <ConversionPanelTrustbar trustBar={reference} variant={variant} background={background}/>
    )
  } 
  if (reference?.__typename === "ContentfulComponentTestimonial") {
    return (
      <ConversionPanelTestimonial testimonial={reference} background={background}/>
    )
  }
}

export default ConversionPanelReference