import React from "react"
import {
  Wrapper,
  HeadingWrapper,
  Heading,
  HeadingOption,
  FilterGroupHeading,
  GroupWrapper,
  FilterWrapper,
} from "../styles/Filters.styled"
import FilterGroup from "./FilterGroup"

const Filters = ({
  categoryFilters,
  setCategoryFilters,
  typeFilters,
  setTypeFilters,
  handleFilterByType,
  otherFilters,
  setOtherFilters,
  handleFilterByOther,
  handleFilterByCategory,
  filterOptions,
  setSortedByDate,
  sortedByDate,
  resetFilters,
}) => {
  return (
    <Wrapper>
      <div>
        <HeadingWrapper>
          <Heading>SORT BY</Heading>
          <HeadingOption
            onClick={() => {
              setSortedByDate(!sortedByDate)
            }}
          >
            Date
          </HeadingOption>
        </HeadingWrapper>
      </div>
      <div>
        <HeadingWrapper>
          <Heading>Filter BY</Heading>
          <HeadingOption onClick={() => resetFilters()}>
            Clear All
          </HeadingOption>
        </HeadingWrapper>
        <GroupWrapper>
          {filterOptions?.categories?.length > 0 && (
            <FilterWrapper>
              <FilterGroupHeading>Categories</FilterGroupHeading>
              <FilterGroup
                options={filterOptions?.categories}
                activeFilters={categoryFilters}
                setActiveFilters={setCategoryFilters}
                handleFilters={handleFilterByCategory}
              />
            </FilterWrapper>
          )}
          {filterOptions?.contentTypes?.length > 0 && (
            <FilterWrapper>
              <FilterGroupHeading>Content Type</FilterGroupHeading>
              <FilterGroup
                options={filterOptions?.contentTypes}
                activeFilters={typeFilters}
                setActiveFilters={setTypeFilters}
                handleFilters={handleFilterByType}
              />
            </FilterWrapper>
          )}
          {filterOptions?.other?.length > 0 && (
            <FilterWrapper>
              <FilterGroupHeading>Other</FilterGroupHeading>
              <FilterGroup
                options={filterOptions?.other}
                activeFilters={otherFilters}
                setActiveFilters={setOtherFilters}
                handleFilters={handleFilterByOther}
              />
            </FilterWrapper>
          )}
        </GroupWrapper>
      </div>
    </Wrapper>
  )
}

export default Filters
