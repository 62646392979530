import React from 'react'
import OptimizedImage from '../../utils/optimizedImage'
import {
  TestimonialContainer,
  QuoteContainer,
  AuthorContainer
} from "./styles/ConversionPanelReference.styled"

const ConversionPanelTestimonial = ({ testimonial, background }) => {
  const quote = testimonial?.quote?.quote
  const headshot = testimonial?.author?.headshot?.file
  const gatsbyImage = testimonial?.author?.headshot?.gatsbyImageData
  const authorName = testimonial?.author?.fullName
  const company = testimonial?.author?.company?.name

  return (
    <TestimonialContainer style={{color: `${background === 'dark' ? 'white' : 'black'}`}}>
      <QuoteContainer>
        "{quote}"
      </QuoteContainer>
      <AuthorContainer>
        <OptimizedImage image={gatsbyImage} src={headshot?.url} alt={`${authorName} headshot`}/>
        <strong>{authorName}</strong>, {company}
      </AuthorContainer>
    </TestimonialContainer>
  )
}

export default ConversionPanelTestimonial