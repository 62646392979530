import styled from "styled-components"

export const TrustBarContainer = styled.div`
  margin: auto 0;
  max-width: 370px;
  .trust-bar-heading {
    font-weight: 900;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: 'Roboto';
    font-style: normal;
  }
  @media (max-width: 992px) {
    max-width: 100%;
    margin-top: 32px;
    .trust-bar-heading {
      text-align: center;
    }
  }
`

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .gatsby-image-wrapper {
    img {
      height: 32px;
      width: auto;
      margin: 16px 32px 0 0;
    }
  }
  img {
    height: 32px;
    width: auto;
    margin: 16px 32px 0 0;
  }
  @media (max-width: 992px) {
    justify-content: space-evenly;
    img {
      margin-right: 0;
    }
  }
`

export const TestimonialContainer = styled.div`
  margin: auto 0;
  max-width: 370px;
  padding: 24px;
  font-family: 'Roboto';
  font-style: normal;
  @media (max-width: 992px) {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const QuoteContainer = styled.div`
  margin-bottom: 12px;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  @media (max-width: 992px) {
    text-align: center;
  }
`

export const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .gatsby-image-wrapper {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  img {
    width: 32px;
    height: 32px;
    border-radius: 6px;
  }
` 