import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Layout } from "@components/Layout"
import Seo from "../components/Seo/Seo"
import {
  SearchHeading,
  Section,
  Wrapper,
  NoResults,
  NoResultsHeading,
} from "../components/GlobalSearch/styles/GlobalSearch.styled"
import {
  formatData,
  contentTypeOptions,
} from "../components/GlobalSearch/utils/formatData"
import { usePagination } from "../components/GlobalSearch/utils/usePagination"
import { useFilters } from "../components/GlobalSearch/utils/filters"
import Filters from "../components/GlobalSearch/components/Filters"
import HeroSearch from "../components/Hero/HeroSearch"
import ResultsGrid from "../components/GlobalSearch/components/ResultsGrid"
import NoResultsSidebar from "../components/GlobalSearch/components/NoResultsSidebar"
import ConversionPanelStandard from "../components/ConversionPanel/ConversionPanelStandard"

const GlobalSearch = props => {
  const formattedData = formatData(props.data)
  const {
    searchValue,
    setSearchValue,
    categoryFilters,
    setCategoryFilters,
    handleFilterByCategory,
    typeFilters,
    setTypeFilters,
    handleFilterByType,
    otherFilters,
    setOtherFilters,
    handleFilterByOther,
    activeFilteredPosts,
    resetFilters,
    setSortedByDate,
    sortedByDate,
  } = useFilters(formattedData)
  const { activePosts, currentPage, pageNumbers, paginate, totalPages } =
    usePagination(10, activeFilteredPosts, 500)
  const filterOptions = contentTypeOptions(activeFilteredPosts)

  //set search value if using navbar search
  useEffect(() => {
    const value = props?.location?.state?.value
    if (value) {
      setSearchValue(value.toLowerCase())
    }
  }, [])

  useEffect(() => {
    console.log(currentPage)
  }, [])

  return (
    <Layout>
      <Seo
        title="Workgrid Software Global Search Results"
        description="Workgrid is a digital workplace platform that simplifies the work day and improves employee experience."
        metaTags="workgrid, digital workplace, enterprise software"
      />
      <HeroSearch setSearchValue={setSearchValue} searchValue={searchValue} />
      <Section id="top">
        <Wrapper>
          <div>
            {searchValue !== "" && activePosts.length === 0 && (
              <SearchHeading>{`No results for "${searchValue}"`}</SearchHeading>
            )}
            {activePosts.length > 0 && (
              <ResultsGrid
                activePosts={activePosts}
                currentPage={currentPage}
                pageNumbers={pageNumbers}
                paginate={paginate}
                totalPages={totalPages}
              />
            )}
            {activePosts.length === 0 && (
              <NoResults>
                <NoResultsHeading>Search Tips</NoResultsHeading>
                <ul>
                  <li>Try searching by product type or category</li>
                  <li>Check your spelling</li>
                  <li>
                    Broaden your search by using fewer or more general words
                  </li>
                </ul>
              </NoResults>
            )}
          </div>
          {activePosts.length > 0 && (
            <Filters
              filterOptions={filterOptions}
              categoryFilters={categoryFilters}
              setCategoryFilters={setCategoryFilters}
              typeFilters={typeFilters}
              setTypeFilters={setTypeFilters}
              handleFilterByType={handleFilterByType}
              otherFilters={otherFilters}
              setOtherFilters={setOtherFilters}
              handleFilterByOther={handleFilterByOther}
              handleFilterByCategory={handleFilterByCategory}
              setSortedByDate={setSortedByDate}
              sortedByDate={sortedByDate}
              resetFilters={resetFilters}
            />
          )}
          {activePosts.length === 0 && (
            <NoResultsSidebar setSearchValue={setSearchValue} />
          )}
        </Wrapper>
      </Section>
      <ConversionPanelStandard
        component={props.data.contentfulComponentConversionPanel}
      />
    </Layout>
  )
}

export default GlobalSearch

export const query = graphql`
  {
    allContentfulPage {
      edges {
        node {
          pageSeo {
            noIndex
          }
          internalName
          slug
          pageSeo {
            seoDescription
          }
          pageCategory {
            title
          }
          createdAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allContentfulResource(
      filter: { id: {ne: "f49ed92d-04a1-53ac-a16f-1a7cec35c611"} }
    ) {
      edges {
        node {
          pageSeo {
            noIndex
          }
          slug
          id
          publishDate(formatString: "MMMM DD, YYYY")
          externalUrl
          category {
            title
          }
          title
          childContentfulResourceDescriptionTextNode {
            description
          }
        }
      }
    }
    allContentfulBlogPost(
      filter: { id: { ne: "8c7ce552-fe16-5084-8505-c662bca6b48a" } }
    ) {
      edges {
        node {
          pageSeo {
            noIndex
          }
          slug
          publishDate(formatString: "MMMM DD, YYYY")
          category {
            title
          }
          title
          description {
            description
          }
        }
      }
    }
    allContentfulKnowledgeCenterPost(
      filter: { id: { ne: "70ae2990-d224-583c-9d7b-b280584d7d3b" } }
    ) {
      edges {
        node {
          pageSeo {
            noIndex
          }
          contentful_id
          id
          slug
          createdAt(formatString: "MMMM DD, YYYY")
          title
          childContentfulKnowledgeCenterPostDescriptionTextNode {
            description
          }
        }
      }
    }
    allContentfulNewsroomPost(
      filter: {contentful_id: {nin: ["3zIH9X62AtcAq0tVp4Y8jB", "4DAr4p4fbj3OYfKBbrvu7c"]}}
    ) {
      edges {
        node {
          pageSeo {
            noIndex
          }
          slug
          contentful_id
          publishDate(formatString: "MMMM DD, YYYY")
          title
          childContentfulNewsroomPostDescriptionTextNode {
            description
          }
        }
      }
    }
    contentfulComponentConversionPanel(
      id: { eq: "b4c28721-bfc7-504c-b7ee-16440e6feb89" }
    ) {
      kicker
      __typename
      id
      textAlign
      alignContent
      background
      subheading {
        raw
      }
      callsToAction {
        contentful_id
        id
        internalLink
        label
        type
        link
        variant
      }
      noTopPadding
      noBottomPadding
      headingSize
      heading
      type
    }
  }
`
