import styled, { css } from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 8px;
  padding-bottom: 0px;
  transition: all 200ms ease-in-out;
  ${props =>
    props.expanded &&
    css`
      padding-bottom: 16px;
      background: #e4e9f1;
      translate: 0px;
    `}
`
export const Button = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  margin: 0px;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 48px;
  color: #000;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1b1b1b;
  transition: border-radius 200ms ease-in-out;
  &:hover {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    background-color: #fff;
    color: black;
    outline: none;
  }
  svg {
    width: 12px;
    height: 12px;
  }
  ${props =>
    props.expanded &&
    css`
      border-radius: 8px 8px 0px 0px;
      svg {
        transform: rotate(180deg);
      }
    `}
`
export const Filters = styled.div`
  width: 100%;
  max-height: 0px;
  transition: max-height 200ms ease-in;
  overflow: hidden;
  ${props =>
    props?.expanded &&
    css`
      max-height: 800px;
    `}
`

export const Icon = styled.img`
  transform: ${props => (props.expanded ? "rotate(180deg)" : "rotate(360deg)")};
`
